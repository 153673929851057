import axios from '@/http/axios'

export function truncateAccountingDatabase() {
    return axios({
        url: 'v1/truncate-all',
        method: 'post'
    })
}

export function getSetting() {
    return axios({
        url: 'v1/admin/settings',
        method: 'get'
    })
}

export function getAppSetting() {
    return axios({
        url: 'v1/admin/settings/app',
        method: 'get'
    })
}

export function getClubSetting() {
    return axios({
        url: 'v1/admin/settings/club',
        method: 'get'
    })
}

export function getMessengerSetting() {
    return axios({
        url: 'v1/admin/settings/messenger',
        method: 'get'
    })
}

export function uploadAvatar(avatar) {
    const data = new FormData()
    data.append('avatar', avatar)

    return axios({
        url: 'v1/upload-avatar',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: 'post'
    })
}

export function editSetting(payload) {
    return axios({
        url: 'v1/admin/settings',
        data: payload,
        method: 'patch'
    })
}

export function editAppSetting(payload) {
    return axios({
        url: 'v1/admin/settings/app',
        data: payload,
        method: 'patch'
    })
}

export function editClubSetting(payload) {
    return axios({
        url: 'v1/admin/settings/club',
        data: payload,
        method: 'patch'
    })
}

export function editFinancialSetting(payload) {
    return axios({
        url: 'v1/admin/settings/financial',
        data: payload,
        method: 'patch'
    })
}

export function editMessengerSetting(payload) {
    return axios({
        url: 'v1/admin/settings/messenger',
        data: payload,
        method: 'patch'
    })
}
